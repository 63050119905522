<template>
    <section class="promo-image" style="background-color: #e7e7e7">
        <div class="container">
            <div v-if="home.promoAds !== undefined" class="row">
                <!--For perfect view image size need 1200x640-->
                <div class="col-md-4 col-sm-6 col-xs-4">
                    <a :href="home.promoAds.find(ads => ads.position_in_column === 1).url">
                        <div class="hovereffect">
                            <img class="img-responsive"
                                :src="'https://eurasiasupplies.com/euadmin/' + home.promoAds.find(ads => ads.position_in_column === 1).image"
                                alt="" />
                        </div>
                    </a>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-4">
                    <a :href="home.promoAds.find(ads => ads.position_in_column === 2).url">
                        <div class="hovereffect">
                            <img class="img-responsive"
                                :src="'https://eurasiasupplies.com/euadmin/' + home.promoAds.find(ads => ads.position_in_column === 2).image"
                                alt="" />
                        </div>
                    </a>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-4">
                    <a :href="home.promoAds.find(ads => ads.position_in_column === 3).url">
                        <div class="hovereffect">
                            <img class="img-responsive"
                                :src="'https://eurasiasupplies.com/euadmin/' + home.promoAds.find(ads => ads.position_in_column === 3).image"
                                alt="" />
                        </div>
                    </a>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-4">
                    <a :href="home.promoAds.find(ads => ads.position_in_column === 4).url">
                        <div class="hovereffect">
                            <img class="img-responsive"
                                :src="'https://eurasiasupplies.com/euadmin/' + home.promoAds.find(ads => ads.position_in_column === 4).image"
                                alt="" />
                        </div>
                    </a>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-4">
                    <a :href="home.promoAds.find(ads => ads.position_in_column === 5).url">
                        <div class="hovereffect">
                            <img class="img-responsive"
                                :src="'https://eurasiasupplies.com/euadmin/' + home.promoAds.find(ads => ads.position_in_column === 5).image"
                                alt="" />
                        </div>
                    </a>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-4">
                    <a :href="home.promoAds.find(ads => ads.position_in_column === 6).url">
                        <div class="hovereffect">
                            <img class="img-responsive"
                                :src="'https://eurasiasupplies.com/euadmin/' + home.promoAds.find(ads => ads.position_in_column === 6).image"
                                alt="" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <!--<div class="container" style="padding: 15px 0px">
            <div class="row">
                <div
                        class="col-md-3 col-6"
                        v-for="specialCategory in home.specialCategories"
                        :key="specialCategory.id"
                >
                    <router-link
                            :to="{
            name: 'category',
            params: {
              slug: specialCategory.slug,
            },
          }"
                            class="offer_img"
                    >

                        <img
                                v-if="specialCategory.special_category_image[0]"
                                :src="'https://www.savershall.com/eurasia-admin/'+specialCategory.special_category_image"
                                alt=""
                        />
                    </router-link>
                </div>
            </div>
        </div>-->
    </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: 'OfferAreaForHome',
    props: {
        msg: String
    },
    computed: {
        ...mapGetters(["home"]),
    },
}
</script>
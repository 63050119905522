<template>
  <div>
    <div class="container-fluid">
      <div class="slider-titile">
        <h4 class="text-center slider-title-text">TOP SELLING PRODUCTS</h4>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row prod-slider-area">
        <div class="col-md-12 col-sm-12 col-12">
          <div class="main-content-2 d-none d-md-block">

            <!--<pre>{{topSelling}}</pre>-->
            <carousel :autoplay="false" :refreshed="true" :items="6" :nav="true" :navText="['', '']" :loop="false"
              :responsive="{
                0: { items: 2, nav: true },
                600: { items: 3, nav: true },
                1200: { items: 6, nav: true },
              }" :margin="20" :dots="false">
              <div class="item" v-for="product in topSelling" :key="product.id">
                <div class="slider-item-area">
                  <div class="slider-image-area">
                    <router-link :to="{
                      name: 'product',
                      params: { slug: product.slug, type: product.product_type },
                    }">
                      <div id="sold_out" style="position: absolute; z-index: 1; margin-top: 25px">
                        <img src="modifyfile/images/sales.png" />
                      </div>
                      <img v-if="product.product_images.length > 0" class="img-fluid" :src="'https://eurasiasupplies.com/euadmin/' +
                        product.product_images.filter((img) => img.web_id === 2)[0].image
                        " alt="" />
                    </router-link>
                  </div>
                  <button type="button" class="quick-view" @click="quickView(product)">
                    QUICK VIEW
                  </button>
                  <div class="slider-prod-titile">
                    <router-link :to="{
                      name: 'product',
                      params: { slug: product.slug, type: product.product_type },
                    }">
                      {{ product.name }}
                    </router-link>
                  </div>

                  <div v-if="product.product_type === 1 || product.product_type === 3" class="prod-old-price">
                    <span v-if="product.product_price" class="price price-m">
                      <span class="value" itemprop="price">
                        <table style="border-spacing: 0px; width: 100%; font-size: 15px">
                          <tbody>
                            <tr v-if="product.product_price[0].offer_price > 0">
                              <td class="start-at" style="
                                text-align: left;
                                letter-spacing: -1px;
                                font-family: verdana, arial, helvetica, sans-serif;
                              ">
                                <b>Was: </b>
                              </td>
                              <td v-if="product.product_price[0].offer_price > 0" class="start-at" style="
                                text-align: left;
                                letter-spacing: -1px;
                                font-family: verdana, arial, helvetica, sans-serif;
                              ">
                                <s>{{
                                  product.product_price[0].offer_price > 0
                                    ? product.product_price[0].price > 0
                                      ? product.product_price[0].price
                                      : ""
                                    : ""
                                }}৳</s>
                              </td>
                            </tr>
                            <tr>
                              <td class="start-at" style="
                                text-align: left;
                                letter-spacing: -1px;
                                font-family: verdana, arial, helvetica, sans-serif;
                              ">
                                <b>Now: </b>
                              </td>
                              <td class="start-at" style="
                                text-align: left;
                                letter-spacing: -1px;
                                font-family: verdana, arial, helvetica, sans-serif;
                              ">
                                {{
                                  product.product_price[0].offer_price
                                    ? product.product_price[0].offer_price
                                    : product.product_price[0].price
                                }}৳
                                <span v-if="
                                  product.product_price[0].offer_price > 0
                                    ? product.product_price[0].price > 0
                                      ? product.product_price[0].price
                                      : ''
                                    : '' !== ''
                                ">
                                  (
                                  {{
                                    percentCalc(
                                      product.product_price[0].offer_price > 0
                                        ? product.product_price[0].price > 0
                                          ? product.product_price[0].price
                                          : ""
                                        : "",
                                      product.product_price[0].offer_price
                                        ? product.product_price[0].offer_price
                                        : product.product_price[0].price
                                    )
                                  }}% )
                                  <small style="
                                    color: grey;
                                    font-weight: 100;
                                    letter-spacing: 1px;
                                  ">/piece</small>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </span>
                    </span>
                    <button v-if="
                      !$store.getters.isExist(product.id) &&
                      product.product_price[0].quantity > 0
                    " style="width: 100%" class="plus product-add-btn home-add-btn"
                      @click="addToBagForSingleProduct(product)">
                      Add to bag
                    </button>
                    <div v-if="$store.getters.isExist(product.id)" class="product_action clearfix"
                      style="margin-top: 5px">
                      <div class="product-add-deduct">
                        <ul>
                          <li>
                            <button @click="deductQty(product)" type="button" class="btn btn-default btn-group-xs">
                              <i class="fa fa-minus"></i>
                            </button>
                          </li>
                          <li>{{ $store.getters.prodQty(product.id) }}</li>
                          <li>
                            <button type="button" class="btn btn-default btn-group-xs" :disabled="product.product_price[0].quantity ===
                              $store.getters.prodQty(product.id)
                              " @click="addQty(product)">
                              <i class="fa fa-plus"></i>
                            </button>
                            <!--                          <button @click="addQty(product)" type="button" class="btn btn-default btn-group-xs"><i-->
                            <!--                              class="fa fa-plus"></i></button>-->
                          </li>
                        </ul>
                      </div>
                      <!--<button class="minus" @click="deductQty(product)"> <i class="fa fa-minus"></i> </button>
                    <span class="product_value">
                      <span class="quantity_value">
                        {{ $store.getters.prodQty(product.id) }}
                      </span>
                    </span>
                    <button :disabled=" product.product_price[0].quantity === $store.getters.prodQty(product.id)"
                            class="plus" @click="addQty(product)"> <i class="fa fa-plus"></i>
                    </button>-->
                    </div>
                  </div>

                  <div v-if="product.product_type === 2" class="prod-old-price">
                    <div class="price price-m">
                      <span class="value" itemprop="price">
                        <table style="border-spacing: 0px; float: left">
                          <tr>
                            <td style="
                              white-space: nowrap;
                              border: none;
                              color: #f60;
                              font-size: 0.85em !important;
                              padding-right: 3px;
                              text-align: right;
                              vertical-align: middle;
                            ">
                              <b>Start At:</b>
                            </td>
                            <td style="
                              color: #f60;
                              border: none;
                              font-size: 13px !important;
                              font-weight: normal;
                              letter-spacing: -1px;
                              font-family: verdana, arial, helvetica, sans-serif;
                            ">
                              <b>
                                <span v-if="product.min_offer_price > 0">{{
                                  product.min_offer_price ? product.min_offer_price : ""
                                  }}৳ -
                                  <s>{{
                                    product.min_offer_price ? product.min_price : ""
                                    }}
                                    ৳</s>
                                </span>
                                <span v-else>{{ product.min_price }}৳</span>
                              </b>
                            </td>
                          </tr>

                          <!--<tr v-if="product.product_price[0].offer_price > 0">
                          <td style="white-space: nowrap; border:none;color: #666666;font-size: 0.85em !important;padding-right: 3px;text-align: right;vertical-align: middle;">
                          </td>
                          <td style="color: #5f0206; border:none;font-size: 13px !important;font-weight: bold;letter-spacing: -1px;font-family: verdana,arial,helvetica,sans-serif;">
                              {{product.product_price[0].offer_price}}৳
                          </td>
                        </tr>-->
                        </table>
                      </span>
                    </div>
                    <br />
                    <div class="product_action action-details">
                      <router-link class="details" :to="{
                        name: 'product',
                        params: { slug: product.slug, type: product.product_type },
                      }">
                        Details
                      </router-link>
                    </div>
                  </div>

                  <!--<div class="prod-brand">
                    <p><span class="brand">
    <a href="https://eurasiasupplies.com/absolute-new-york"
                           target="_blank" title="ABSOLUTE NEW YORK">BRAND</a>
  </span>
                    </p>

                </div>-->
                  <br />
                  <div class="prod-revewis">
                    <div class="add-to-wishlist"></div>
                  </div>
                </div>
              </div>
            </carousel>
            <!--<div class="custoPrvBtn-2"><i class="fa fa-angle-left"></i></div>

          <div class="custoNxtBtn-2"><i class="fa fa-angle-right"></i></div>-->
          </div>
        </div>
        <div class="main-content-2 d-sm-block d-none">
          <!--<pre>{{topSelling}}</pre>-->
          <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 5px">
            <div class="item" v-for="product in topSelling" :key="product.id">
              <div class="slider-item-area">
                <div class="slider-image-area">
                  <router-link :to="{
                    name: 'product',
                    params: { slug: product.slug, type: product.product_type },
                  }">
                    <div id="sold_out" style="position: absolute; z-index: 1; margin-top: 25px">
                      <img src="modifyfile/images/sales.png" />
                    </div>
                    <img v-if="product.product_images.length > 0" class="img-fluid" :src="'https://eurasiasupplies.com/euadmin/' +
                      product.product_images.filter((img) => img.web_id === 2)[0].image
                      " alt="" />
                  </router-link>
                </div>
                <div class="d-none d-md-block">
                  <button type="button" class="quick-view" @click="quickView(product)">
                    QUICK VIEW
                  </button>
                </div>
                <div class="slider-prod-titile">
                  <router-link :to="{
                    name: 'product',
                    params: { slug: product.slug, type: product.product_type },
                  }">
                    {{ product.name }}
                  </router-link>
                </div>

                <div v-if="product.product_type === 1 || product.product_type === 3" class="prod-old-price">
                  <span v-if="product.product_price" class="price price-m">
                    <span class="value" itemprop="price">
                      <table style="border-spacing: 0px; width: 100%; font-size: 15px">
                        <tbody>
                          <tr v-if="product.product_price[0].offer_price > 0">
                            <td class="start-at" style="
                    text-align: left;
                    letter-spacing: -1px;
                    font-family: verdana, arial, helvetica, sans-serif;
                  ">
                              <b>Was: </b>
                            </td>
                            <td v-if="product.product_price[0].offer_price > 0" class="start-at" style="
                    text-align: left;
                    letter-spacing: -1px;
                    font-family: verdana, arial, helvetica, sans-serif;
                  ">
                              <s>{{
                                product.product_price[0].offer_price > 0
                                  ? product.product_price[0].price > 0
                                    ? product.product_price[0].price
                                    : ""
                                  : ""
                              }}৳</s>
                            </td>
                          </tr>
                          <tr>
                            <td class="start-at" style="
                    text-align: left;
                    letter-spacing: -1px;
                    font-family: verdana, arial, helvetica, sans-serif;
                  ">
                              <b>Now: </b>
                            </td>
                            <td class="start-at" style="
                    text-align: left;
                    letter-spacing: -1px;
                    font-family: verdana, arial, helvetica, sans-serif;
                  ">
                              {{
                                product.product_price[0].offer_price
                                  ? product.product_price[0].offer_price
                                  : product.product_price[0].price
                              }}৳
                              <span v-if="
                                product.product_price[0].offer_price > 0
                                  ? product.product_price[0].price > 0
                                    ? product.product_price[0].price
                                    : ''
                                  : '' !== ''
                              ">
                                (
                                {{
                                  percentCalc(
                                    product.product_price[0].offer_price > 0
                                      ? product.product_price[0].price > 0
                                        ? product.product_price[0].price
                                        : ""
                                      : "",
                                    product.product_price[0].offer_price
                                      ? product.product_price[0].offer_price
                                      : product.product_price[0].price
                                  )
                                }}% )
                                <small style="
                        color: grey;
                        font-weight: 100;
                        letter-spacing: 1px;
                      ">/piece</small>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </span>
                  <button v-if="
                    !$store.getters.isExist(product.id) &&
                    product.product_price[0].quantity > 0
                  " style="width: 100%" class="plus product-add-btn home-add-btn"
                    @click="addToBagForSingleProduct(product)">
                    Add to bag
                  </button>
                  <div v-if="$store.getters.isExist(product.id)" class="product_action clearfix"
                    style="margin-top: 5px">
                    <div class="product-add-deduct">
                      <ul>
                        <li>
                          <button @click="deductQty(product)" type="button" class="btn btn-default btn-group-xs">
                            <i class="fa fa-minus"></i>
                          </button>
                        </li>
                        <li>{{ $store.getters.prodQty(product.id) }}</li>
                        <li>
                          <button type="button" class="btn btn-default btn-group-xs" :disabled="product.product_price[0].quantity ===
                            $store.getters.prodQty(product.id)
                            " @click="addQty(product)">
                            <i class="fa fa-plus"></i>
                          </button>
                          <!--                          <button @click="addQty(product)" type="button" class="btn btn-default btn-group-xs"><i-->
                          <!--                              class="fa fa-plus"></i></button>-->
                        </li>
                      </ul>
                    </div>
                    <!--<button class="minus" @click="deductQty(product)"> <i class="fa fa-minus"></i> </button>
        <span class="product_value">
          <span class="quantity_value">
            {{ $store.getters.prodQty(product.id) }}
          </span>
        </span>
        <button :disabled=" product.product_price[0].quantity === $store.getters.prodQty(product.id)"
                class="plus" @click="addQty(product)"> <i class="fa fa-plus"></i>
        </button>-->
                  </div>
                </div>

                <div v-if="product.product_type === 2" class="prod-old-price">
                  <div class="price price-m">
                    <span class="value" itemprop="price">
                      <table style="border-spacing: 0px; float: left">
                        <tr>
                          <td style="
                  white-space: nowrap;
                  border: none;
                  color: #f60;
                  font-size: 0.85em !important;
                  padding-right: 3px;
                  text-align: right;
                  vertical-align: middle;
                ">
                            <b>Start At:</b>
                          </td>
                          <td style="
                  color: #f60;
                  border: none;
                  font-size: 13px !important;
                  font-weight: normal;
                  letter-spacing: -1px;
                  font-family: verdana, arial, helvetica, sans-serif;
                ">
                            <b>
                              <span v-if="product.min_offer_price > 0">{{
                                product.min_offer_price ? product.min_offer_price : ""
                                }}৳ -
                                <s>{{
                                  product.min_offer_price ? product.min_price : ""
                                  }}
                                  ৳</s>
                              </span>
                              <span v-else>{{ product.min_price }}৳</span>
                            </b>
                          </td>
                        </tr>

                        <!--<tr v-if="product.product_price[0].offer_price > 0">
              <td style="white-space: nowrap; border:none;color: #666666;font-size: 0.85em !important;padding-right: 3px;text-align: right;vertical-align: middle;">
              </td>
              <td style="color: #5f0206; border:none;font-size: 13px !important;font-weight: bold;letter-spacing: -1px;font-family: verdana,arial,helvetica,sans-serif;">
                  {{product.product_price[0].offer_price}}৳
              </td>
            </tr>-->
                      </table>
                    </span>
                  </div>
                  <br />
                  <div class="product_action action-details">
                    <router-link class="details" :to="{
                      name: 'product',
                      params: { slug: product.slug, type: product.product_type },
                    }">
                      Details
                    </router-link>
                  </div>
                </div>

                <!--<div class="prod-brand">
        <p><span class="brand">
<a href="https://eurasiasupplies.com/absolute-new-york"
               target="_blank" title="ABSOLUTE NEW YORK">BRAND</a>
</span>
        </p>

    </div>-->
                <br />
                <div class="prod-revewis">
                  <div class="add-to-wishlist"></div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="d-sm-block d-none" style="margin-top: 20px;">
            <button>Load More</button>
          </div> -->
          <!--<div class="custoPrvBtn-2"><i class="fa fa-angle-left"></i></div>

<div class="custoNxtBtn-2"><i class="fa fa-angle-right"></i></div>-->
        </div>
        <div v-if="quickViewModal">
          <transition name="modal">
            <div class="modal-mask">
              <div class="modal-wrapper">
                <div class="modal-dialog modal-lg modal-ku" role="document">
                  <div class="modal-content">
                    <div class="modal-header eu-modal-header">
                      <h5 class="modal-title eu-modal-title" style="color: #fff">
                        {{ productDetails.name }}
                      </h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        style="font-weight: 100">
                        <span aria-hidden="true" @click="quickViewModal = false">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body quick-modal-body">
                      <!--<pre>{{productDetails}}</pre>-->
                      <img v-if="loading" :src="'/eurasia/img/logo/loading.gif'" style="width: 100px; height: 80px"
                        alt="logo" />
                      <template v-if="
                        productDetails.product_type === 1 ||
                        productDetails.product_type === 3
                      ">
                        <div v-if="!loading" class="row">
                          <div class="col-md-12">
                            <div class="col-md-6">
                              <div class="thumbnail-img col-md-2">
                                <ul>
                                  <li class="thumb-img" v-for="image in productDetails.product_images">
                                    <img @click="asignImage(image.large_image)" :src="'https://eurasiasupplies.com/euadmin/' +
                                      image.large_image
                                      " alt="" />
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-12 wrap-border">
                                <div class="immage-zoomer">
                                  <inner-image-zoom class="large-img-sz" :src="'https://eurasiasupplies.com/euadmin/' +
                                    `${inImage == null
                                      ? productDetails.product_images[0].large_image
                                      : inImage
                                    }`
                                    " :zoomScale="1" zoomType="hover" />
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <!--<pre>{{productDetails}}</pre>-->
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="product-details">
                                    <div class="product-desc">
                                      <h4>{{ productDetails.name }}</h4>
                                      <p>
                                        by
                                        <b class="brand-name">
                                          <router-link :to="{
                                            name: 'brand',
                                            params: { slug: productDetails.brand.slug },
                                          }">
                                            {{ productDetails.brand.name }}
                                          </router-link>
                                        </b>
                                      </p>
                                      <p class="sku">
                                        <b>SKU : {{ productDetails.sku }}</b>
                                      </p>
                                    </div>
                                    <div class="price">
                                      <b>Sale:
                                        <span class="price-tag" style="font-size: 14px">
                                          <span v-if="
                                            productDetails.product_price[0]
                                              .offer_price > 0
                                              ? productDetails.product_price[0].price >
                                                0
                                                ? productDetails.product_price[0].price
                                                : ''
                                              : '' !== ''
                                          ">
                                            Was:
                                            <del>{{
                                              productDetails.product_price[0]
                                                .offer_price > 0
                                                ? productDetails.product_price[0].price >
                                                  0
                                                  ? productDetails.product_price[0].price
                                                  : ""
                                                : ""
                                            }}</del>৳</span>
                                          <span>
                                            Now:
                                            {{
                                              productDetails.product_price[0].offer_price
                                                ? productDetails.product_price[0]
                                                  .offer_price
                                                : productDetails.product_price[0].price
                                            }}৳
                                            <span v-if="
                                              productDetails.product_price[0]
                                                .offer_price > 0
                                                ? productDetails.product_price[0]
                                                  .price > 0
                                                  ? productDetails.product_price[0]
                                                    .price
                                                  : ''
                                                : '' !== ''
                                            ">(
                                              {{
                                                percentCalc(
                                                  productDetails.product_price[0]
                                                    .offer_price > 0
                                                    ? productDetails.product_price[0]
                                                      .price > 0
                                                      ? productDetails.product_price[0]
                                                        .price
                                                      : ""
                                                    : "",
                                                  productDetails.product_price[0]
                                                    .offer_price
                                                    ? productDetails.product_price[0]
                                                      .offer_price
                                                    : productDetails.product_price[0]
                                                      .price
                                                )
                                              }}% )</span>
                                            <small style="
                                                color: grey;
                                                font-weight: 100;
                                                letter-spacing: 1px;
                                              ">/piece</small></span>
                                        </span>
                                      </b>
                                      <p class="dsc">{{ productDetails.name }}</p>
                                      <p class="dsc">
                                        Made in
                                        {{
                                          productDetails.country_of_manufacture_id.name
                                        }}
                                      </p>
                                    </div>

                                    <div class="sales-btn-area">
                                      <div v-if="$store.getters.isExist(productDetails.id)" class="counter-btn">
                                        <button class="btn" @click="deductQty(productDetails)">
                                          -
                                        </button>

                                        <button class="value-btn" readonly>
                                          {{ $store.getters.prodQty(productDetails.id) }}
                                        </button>

                                        <button class="btn" :disabled="productDetails.product_price[0].quantity ===
                                          $store.getters.prodQty(productDetails.id)
                                          " @click="addQty(productDetails)">
                                          +
                                        </button>
                                      </div>
                                    </div>
                                    <div class="stock" v-if="
                                      productDetails.product_type == 1">
                                      <p class="stock-st">
                                        <b><span>Stock Status: </span>
                                          <span v-if="
                                            productDetails.product_price[0].quantity > 0
                                          ">{{
                                            productDetails.product_price[0].quantity
                                          }}</span>
                                          <span v-else>0</span> available only</b>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div v-if="
                                    !$store.getters.isExist(productDetails.id) &&
                                    productDetails.product_price[0].quantity > 0
                                  " class="cart-btn">
                                    <button @click="buyNowForSingleProduct(productDetails)"
                                      class="btn btn-primary buy-now">
                                      Buy Now
                                    </button>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div v-if="
                                    !$store.getters.isExist(productDetails.id) &&
                                    productDetails.product_price[0].quantity > 0
                                  " class="cart-btn">
                                    <button @click="addToBagForSingleProduct(productDetails)"
                                      class="btn btn-primary add-bag">
                                      Add to bag
                                    </button>
                                  </div>
                                </div>

                                <div v-if="productDetails.product_price[0].quantity < 1" class="col-md-12">
                                  <div class="form-group" style="margin-bottom: 1rem">
                                    <input type="text" class="form-control" v-model="name" placeholder="Your Name" />
                                  </div>
                                  <div class="form-group">
                                    <input type="text" class="form-control" v-model="mobileNo"
                                      placeholder="Your Mobile Number" />
                                  </div>
                                  <div class="form-group">
                                    <button type="button" class="btn btn-primary" @click="
                                      notifyMeForSubmit(name, mobileNo, productDetails)
                                      ">
                                      Notify Me
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="productDetails.product_type === 2">
                        <div v-if="!loading" class="row">
                          <div class="col-md-12">
                            <div class="col-md-6">
                              <div class="thumbnail-img group-thumb-img col-md-12">
                                <ul>
                                  <li class="thumb-img" v-for="image in singleProductImg === null
                                    ? productDetails.product_images.filter(
                                      (img) => img.web_id === 2
                                    )
                                    : singleProductImg.filter(
                                      (img) => img.web_id === 2
                                    )">
                                    <img @click="asignImage(image.large_image)" :src="'https://eurasiasupplies.com/euadmin/' +
                                      image.thumb_image
                                      " alt="" />
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-12 wrap-border">
                                <div class="immage-zoomer">
                                  <inner-image-zoom class="large-img-sz" :src="'https://eurasiasupplies.com/euadmin/' +
                                    `${inImage == null
                                      ? productDetails.product_images.filter(
                                        (img) => img.web_id === 2
                                      )[0].large_image
                                      : inImage
                                    }`
                                    " :zoomScale="1" zoomType="hover" />
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="product-details">
                                    <div class="product-desc">
                                      <h4>{{ productName }}</h4>
                                      <p>
                                        by
                                        <b class="brand-name">
                                          <router-link :to="{
                                            name: 'brand',
                                            params: { slug: productDetails.brand.slug },
                                          }">
                                            {{ productDetails.brand.name }}
                                          </router-link>
                                        </b>
                                      </p>
                                      <p v-if="origin">
                                        <b>Manufacturer Country: {{ origin }}</b>
                                      </p>
                                      <p v-if="sku" class="sku">
                                        <b>SKU : {{ sku }}</b>
                                      </p>
                                    </div>

                                    <div class="price">
                                      <b>
                                        <span class="price-tag">
                                          <h5 v-if="showPriceRange">
                                            <span>Sale :</span>
                                            {{ productDetails.min_price }}৳ -
                                            {{ productDetails.max_price }}৳
                                          </h5>
                                          <h5 v-if="!showPriceRange">
                                            <span v-if="Object.keys(singleProct).length !== 0">
                                              <span v-if="
                                                singleProct.product_price[0]
                                                  .offer_price > 0
                                              ">
                                                <span style="
                                                    font-size: 17px;
                                                    font-weight: 500;
                                                  ">Price :</span>
                                                {{
                                                  singleProct.product_price[0].offer_price
                                                }}
                                              </span>
                                              <span v-else><span style="
                                                    font-size: 17px;
                                                    font-weight: 500;
                                                  ">Price :</span>
                                                {{
                                                  singleProct.product_price[0].price
                                                }}</span>
                                              TK
                                            </span>
                                          </h5>
                                        </span>
                                      </b>
                                      <p class="dsc">{{ productDetails.name }}</p>
                                    </div>
                                    <div class="product-color">
                                      <div class="color-name">
                                        <p><b>Color:</b></p>
                                      </div>
                                      <div class="color-shade clearfix">
                                        <ul>
                                          <li v-for="color in productDetails.groupProducts.filter(
                                            (x) =>
                                              x.product.hasOwnProperty('product_color')
                                          )" :key="color.id">
                                            <img :class="selectedClrId === color.id
                                              ? 'activeImg'
                                              : ''
                                              " v-if="color.product.product_color !== null"
                                              @click="productByColor(color, color.id)" style="
                                                height: 25px;
                                                width: 50px;
                                                border-radius: 3px;
                                              " :src="'https://eurasiasupplies.com/euadmin/' +
                                                color.product.product_color.color_image
                                                " alt="" />
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div v-if="Object.keys(singleProct).length !== 0" class="sales-btn-area clearfix">
                                      <div v-if="$store.getters.isExist(singleProct.id)" class="counter-btn">
                                        <button class="btn" @click="deductQty(singleProct)">
                                          -
                                        </button>
                                        <button class="value-btn" readonly>
                                          {{ $store.getters.prodQty(singleProct.id) }}
                                        </button>
                                        <button class="btn" :disabled="singleProct.product_price[0].quantity ===
                                          $store.getters.prodQty(singleProct.id)
                                          " @click="addQty(singleProct)">
                                          +
                                        </button>
                                      </div>
                                    </div>
                                    <div v-if="Object.keys(singleProct).length !== 0" class="stock clearfix">
                                      <p v-if="singleProct.hasOwnProperty('product_price')" class="stock-st">
                                        <b><span>Stock Status: </span>
                                          <span v-if="
                                            productDetails.product_price[0].quantity > 0
                                          ">{{
                                            productDetails.product_price[0].quantity
                                          }}</span>
                                          <span v-else>0</span> available only</b>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div v-if="groupProductMsg" class="alert alert-danger" role="alert">
                                    {{ groupProductMsg }}
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="cart-btn">
                                    <button @click="buyNow()" class="btn btn-primary buy-now">
                                      Buy Now
                                    </button>
                                  </div>
                                </div>
                                <div v-if="!$store.getters.isExist(singleProct.id)" class="col-md-6">
                                  <div class="cart-btn">
                                    <button @click="addToBag()" class="btn btn-primary add-bag">
                                      Add to bag
                                    </button>
                                  </div>
                                </div>
                                <div v-if="Object.keys(singleProct).length !== 0">
                                  <div v-if="singleProct.product_price[0].quantity < 1" class="col-md-12">
                                    <div class="form-group" style="margin-bottom: 1rem">
                                      <input type="text" class="form-control" v-model="name" placeholder="Your Name" />
                                    </div>
                                    <div class="form-group">
                                      <input type="text" class="form-control" v-model="mobileNo"
                                        placeholder="Your Mobile Number" />
                                    </div>
                                    <div class="form-group">
                                      <button type="button" class="btn btn-primary" @click="
                                        notifyMeForSubmit(name, mobileNo, singleProct)
                                        ">
                                        Notify Me
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" @click="quickViewModal = false">
                        Close
                      </button>
                      <!--<button type="button" class="btn btn-primary"
                              @click="notifyMeForSubmit(name, mobileNo, notifyMeData)">
                          Notify Me
                      </button>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import { mapGetters } from "vuex";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import "../../assets/product_home_global.css";

export default {
  name: "TopSellingProductForHome",
  data() {
    return {
      showModal: false,
      quickViewModal: false,
      notifyMeData: "",
      mobileNo: "",
      name: "",
      proPrice: 0,
      inImage: null,
      singleProductImg: null,
      showPriceRange: true,
      productName: "",
      brandName: "",
      origin: "",
      slug: "",
      description: "",
      price: "",
      sku: "",
      deliveryCharge: "",
      colors: [],
      sizes: [],
      singleProct: {},
      selectedClrId: 0,
      isSelected: false,
      img: "",
      allProduct: [],
      apiLoad: false,
      productLoading: false,
      groupProductMsg: "",
    };
  },
  components: {
    carousel,
    "inner-image-zoom": InnerImageZoom,
  },
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters([
      "topSelling",
      "accessToken",
      "customer",
      "productDetails",
      "loading",
      "productSize",
      "productColor",
    ]),
  },
  mounted() {
    // this.$store.dispatch("getTopSelling");
    // this.$store.dispatch("getHome");
    // console.log("Hello Home");
  },
  methods: {
    addToBagForSingleProduct(product) {
      product.cartImage = product.product_images.filter(
        (img) => img.web_id === 2
      )[0].thumb_image;
      product.qty = 1;
      product.product_price[0].offer_price > 0
        ? (product.unitPrice = product.product_price[0].offer_price)
        : (product.unitPrice = product.product_price[0].price);

      this.$store.dispatch("addToBag", product);
      // console.log("product from component", product);
    },
    buyNowForSingleProduct(product) {
      // console.log("Product=", product);
      if (product.product_type === 1 || product.product_type === 3) {
        product.qty = 1;
        product.cartImage = product.product_images.filter(
          (img) => img.web_id === 2
        )[0].thumb_image;
        product.product_price[0].offer_price > 0
          ? (product.unitPrice = product.product_price[0].offer_price)
          : (product.unitPrice = product.product_price[0].price);
        this.$store.dispatch("addToBag", product);
      } else {
        product.qty = 1;
        product.cartImage = product.product_images.filter(
          (img) => img.web_id === 2
        )[0].thumb_image;
        product.min_offer_price > 0
          ? (product.unitPrice = product.min_offer_price)
          : (product.unitPrice = product.min_price);

        this.$store.dispatch("addToBag", product);
      }
      //if (this.accessToken) {
      this.$router.push("/checkout").catch(() => { });
      //} else {
      //  this.$router.push({ name: "login" }).catch(() => { });
      // }
    },
    addToBag() {
      if (this.isSelected === false) {
        this.groupProductMsg = "Please select color or size.";
        //alert("Please select color/size");
      } else {
        if (this.singleProct.product_price[0].quantity > 0) {
          // console.log("single ===>", this.singleProct);
          this.singleProct.cartImage = this.singleProct.product_images.filter(
            (img) => img.web_id === 2
          )[0].thumb_image;
          this.singleProct.qty = 1;
          this.singleProct.product_price[0].offer_price > 0
            ? (this.singleProct.unitPrice = this.singleProct.product_price[0].offer_price)
            : (this.singleProct.unitPrice = this.singleProct.product_price[0].price);

          this.$store.dispatch("addToBag", this.singleProct);
          // console.log("product from component", this.singleProct);
        } else {
          this.groupProductMsg = "Out of stock.";
        }
      }
    },
    buyNow() {
      if (this.isSelected === false) {
        this.groupProductMsg = "Please select color or size.";
        //alert("Please select color/size");
      } else {
        if (this.singleProct.product_price[0].quantity > 0) {
          if (
            this.singleProct.product_type === 1 ||
            this.singleProct.product_type === 3
          ) {
            this.singleProct.qty = 1;
            this.singleProct.cartImage = this.singleProct.product_images.filter(
              (img) => img.web_id === 2
            )[0].thumb_image;
            this.singleProct.product_price[0].offer_price > 0
              ? (this.singleProct.unitPrice = this.singleProct.product_price[0].offer_price)
              : (this.singleProct.unitPrice = this.singleProct.product_price[0].price);
            this.$store.dispatch("addToBag", this.singleProct);
          } else {
            this.singleProct.qty = 1;
            this.singleProct.cartImage = this.singleProct.product_images.filter(
              (img) => img.web_id === 2
            )[0].thumb_image;
            this.singleProct.min_offer_price > 0
              ? (this.singleProct.unitPrice = this.singleProct.min_offer_price)
              : (this.singleProct.unitPrice = this.singleProct.min_price);

            this.$store.dispatch("addToBag", this.singleProct);
          }
          //if (this.accessToken) {
          this.$router.push("/checkout").catch(() => { });
          //} else {
          // this.$router.push({ name: "login" }).catch(() => { });
          //}
        } else {
          this.groupProductMsg = "Out of stock.";
        }
      }
    },
    addQty(product) {
      // // console.log('update', product.product_price[0].tier_quantity_three)
      this.$store.dispatch("addQty", product);
      // if (product.qty <= product.product_price[0].quantity) {
      //   if (
      //       product.qty >= product.product_price[0].tier_quantity_three &&
      //       product.product_price[0].tier_quantity_three !== 0
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_three;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_two &&
      //       product.qty < product.product_price[0].tier_quantity_three
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_two;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_one &&
      //       product.qty < product.product_price[0].tier_price_two
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_one;
      //   } else if (product.product_price[0].offer_price) {
      //     this.proPrice = product.product_price[0].offer_price;
      //   } else {
      //     this.proPrice = product.product_price[0].price;
      //   }
      // }
    },
    deductQty(product) {
      if (this.$store.getters.prodQty(product.id) > 0) {
        this.$store.dispatch("deductQty", product);
      }
      if (this.$store.getters.prodQty(product.id) == 0) {
        this.$store.dispatch("removeFromCart", product);
      }
      // if (product.qty <= product.product_price[0].quantity) {
      //   if (
      //       product.qty >= product.product_price[0].tier_quantity_three &&
      //       product.product_price[0].tier_quantity_three !== 0
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_three;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_two &&
      //       product.qty < product.product_price[0].tier_quantity_three
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_two;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_one &&
      //       product.qty < product.product_price[0].tier_price_two
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_one;
      //   } else if (product.product_price[0].offer_price) {
      //     this.proPrice = product.product_price[0].offer_price;
      //   } else {
      //     this.proPrice = product.product_price[0].price;
      //   }
      // }
    },
    quickView(product) {
      this.groupProductMsg = "";
      this.isSelected = false;
      this.$store.dispatch(
        "getProductDetails",
        product.slug + "/" + product.product_type
      );
      if (product.product_type === 1) {
      }
      if (product.product_type === 2) {
        this.inImage = null;
        this.singleProductImg = null;
        this.singleProct = {};
        // console.log("Quick view Product tp 2", product.product_type);
        this.productName = this.productDetails.name;
        // console.log("Quick view Product tp 2nnn", this.productName);
        this.setProductInfo();
      }
      this.quickViewModal = true;
    },
    asignImage(img) {
      this.inImage = img;
    },
    setProductInfo() {
      // console.log("Product details", this.productDetails);
      this.productName = this.productDetails.name;
      this.brandName = this.productDetails.brand.name;
      //this.origin =   this.productDetails.groupProducts[0].product.country_of_manufacture_id.name;

      this.slug = this.productDetails.brand.slug;

      this.description = this.productDetails.short_description;
      this.deliveryCharge = this.productDetails.flat_rate_value;
      // console.log("99999");
      if (this.colors) {
        this.colors = this.productColor;
      }
      if (this.productSize) {
        this.sizes = this.productSize;
      }
      this.sku = this.productDetails.sku;
      // console.log(this.productDetails);
    },
    productByColor(product, sId) {
      this.groupProductMsg = "";
      this.showPriceRange = false;

      this.showPriceRange = false;
      this.productName = product.product.name;
      this.brandName = product.product.brand.name;
      this.origin = product.product.country_of_manufacture_id.name;
      this.slug = product.product.brand.slug;
      this.description = product.product.short_description;
      this.deliveryCharge = product.product.flat_rate_value;
      this.price = product.product.product_price[0].price;
      this.singleProct = product.product;
      this.sku = product.product.sku;
      this.isSelected = true;
      this.selectedClrId = sId;
      this.singleProductImg = product.product.product_images;
      this.inImage = product.product.product_images.filter(
        (img) => img.web_id === 2
      )[0].large_image;
      this.$store.dispatch("setZoomimage", product.product.product_images);
      // console.log(product.product);
    },
    notifyMe(data) {
      this.notifyMeData = data;
      this.showModal = true;
    },
    notifyMeForSubmit(name, phone, product) {
      if (name !== "" && phone !== "" && product.id) {
        let notifyDataForPass = {
          name: name,
          phone: phone,
          product_id: product.id,
        };
        this.$store.dispatch("soldOutNotifyMe", notifyDataForPass).then((res) => {
          // console.log("Response Data = ", res);
          if (res === undefined) {
            window.alert("Successfully Submitted.");
          }
        });
        // console.log("Data for pass: ", notifyDataForPass);
      }
      this.showModal = false;
    },
    percentCalc(wasVal, newVal) {
      if (newVal && wasVal) {
        var per = (newVal * 100) / wasVal;
        return Math.round(100 - per);
      }
    },
  },
};
</script>
<!--
<style>
.thumbnail-img.group-thumb-img ul {
  text-align: left;
}
.thumbnail-img.group-thumb-img ul li {
  display: inline-block !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
li.thumb-img {
  width: 100px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 3px;
}

li.thumb-img img {
  width: 100px;
  padding: 10px;
}

li.thumb-img:hover {
  cursor: pointer;
}
.immage-zoomer {
  background: #ddd9;
}
.product-add-deduct ul {
  padding: 0;
  margin: 0;
  text-align: center;
}

.product-add-deduct ul li {
  display: inline-block;
  margin: 0px 15px;
}

.product-add-deduct {
  margin: 5px 0px;
}

.product-add-deduct button {
  padding: 0;
  border: 1px solid #cc0011;
  color: red;
}
    a.details {
        display: block;
        width: 100%;
        background: #434343;
        color: #ffffff;
        padding: 11px 0px;
        font-size: 14px;
    }
    .owl-nav {
        position: absolute;
        top: 100px !important;
        width: 100%;
        display: flex;
        height: 0;
        margin: 0 !important;
        justify-content: space-between;
    }

    .owl-prev, .owl-next {
        font-size: 48px !important;
        color: #7a7a78 !important;
        height: 100px;
        padding: 22px;
        font-weight: bolder;
        padding-top: 35px !important;
        width: 35px !important;
    }
    /*.owl-next {*/
    /*    position: absolute;*/
    /*    right: -43px;*/
    /*    top:-54px*/
    /*}*/
    /*.owl-prev {*/
    /*    position: absolute;*/
    /*    left: -43px;*/
    /*    top:-54px*/
    /*}*/
    .slider-item-area {
        border: 1px solid transparent;
        padding: 5px;
        height: 310px;
    }
    .slider-prod-titile {
        text-align: center;
        margin: 10px auto;
        padding: 12px 22px 12px 2px;
        width: 200px;
        height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .slider-item-area{
      height: max-content !important;
    }
    @media(min-width:1367px){
        .prod-slider-area .owl-nav {
            top: 144px !important;
        }
    }
    @media (max-width: 959px) {
        .start-at{
            white-space: nowrap;
            border:none;
            color: #f60;
            font-size: 11px !important;
            padding-right: 3px;
            text-align: right;
            vertical-align: middle;
        }
      .quick-modal-body{
        height: 70vh;
        overflow: scroll;
      }
    }
</style>-->
